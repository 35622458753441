import React from 'react'
import pageQuery from '../../pageQuery'
import Img from 'gatsby-image'
import useWidth from 'src/hooks/window/useWidth'
import { WIDTH_MD } from 'src/utils/breakpoints'

import * as S from './style'
import useDataLayer from 'src/hooks/useDataLayer/dataLayerBody'

interface ISeuMelhorAmigoMereceViverBem {
  setOpenModal: (state: boolean) => void;
}

const SeuMelhorAmigoMereceViverBem = ({ setOpenModal }: ISeuMelhorAmigoMereceViverBem) => {
  const data = pageQuery()
  const windowWidth = useWidth(100)
  const [ sendDatalayerEvent ] = useDataLayer()

  return (
    <S.SectionWrapper className='py-5'>
      <div className='container'>
        <div className='row align-items-md-center'>
          <div className='col-12 col-md-6 order-md-last mb-4 mb-md-0'>
            <S.ImgGrid>
              <Img fluid={data.bannerSeuMelhorAmigoDogAndCat.fluid} className='grid-image-1' alt='Cachorro com um gato na cabeça' />
              <Img fluid={data.bannerSeuMelhorAmigoCat.fluid} className='grid-image-2' alt='Gato laranja miando' />
              <Img fluid={data.bannerSeuMelhorAmigoDog.fluid} className='grid-image-3' alt='Cachorro marrom claro olhando para sentado e olhando para a câmera' />
            </S.ImgGrid>
          </div>
          <div className='col-12 col-md-6'>
            <h1>
              <span className='fs-14 lh-17 fs-md-16 lh-md-20 fs-lg-20 lh-lg-25 fw-700 font-inter text-black mb-3 mini-title d-block'>Plano de Saúde Pet Inter</span>
              <span className='fs-32 lh-40 fs-md-40 lh-md-50 fs-lg-60 lh-lg-80 fw-400 text-black mb-4 d-block'>Seu melhor amigo merece viver bem</span>
            </h1>
            <p className='fs-14 lh-17 fs-md-16 lh-md-20 fs-lg-18 lh-lg-22 fw-400 text-black mb-3'>A qualidade de vida do seu pet é a sua prioridade, não é? Assim como nós, os nossos melhores amigos precisam de cuidados com a saúde.</p>
            <p className='fs-14 lh-17 fs-md-16 lh-md-20 fs-lg-18 lh-lg-22 fw-400 text-black mb-0'>Por isso, conte com o <span className='fw-700'>Plano de Saúde Pet</span> para gatos e cachorros. Tenha acesso a consultas veterinárias, exames, vacinas, cirurgias e muuuito mais, sem doer no seu bolso.</p>
            <div>
              <a
                className='btn btn-orange--extra fs-12 lh-17 fw-700 rounded-2 text-none mw-100 mt-4'
                title='Contratar agora'
                aria-label='contratar plano de saúde pet'
                onClick={() => {
                  if (windowWidth < WIDTH_MD) {
                    window && window.location.replace('https://inter-co.onelink.me/Qyu7/qdyrxsfi')
                    sendDatalayerEvent({
                      section: 'dobra_01',
                      element_action: 'click button',
                      element_name: 'Contratar Agora',
                      section_name: 'Plano de Saúde Pet Inter',
                      redirect_url: 'https://inter-co.onelink.me/Qyu7/qdyrxsfi',
                    })
                  } else {
                    setOpenModal(true)
                    sendDatalayerEvent({
                      section: 'dobra_01',
                      element_action: 'click button',
                      element_name: 'Contratar Agora',
                      section_name: 'Plano de Saúde Pet Inter',
                    })
                  }
                }}
              >
                Contratar agora
              </a>
            </div>
          </div>
        </div>
      </div>
    </S.SectionWrapper>
  )
}

export default SeuMelhorAmigoMereceViverBem
